import store from '@/store'

export const submitHiddenFrame = (html: string) => {
    const iframe: any = document.createElement('iframe')

    iframe.src = 'about:blank'

    document.body.appendChild(iframe)

    iframe.contentWindow.document.open()
    iframe.contentWindow.document.write(html)
    iframe.contentWindow.document.close()

    setTimeout(() => store.dispatch('application/update'), 500)
}

export const openForm3ds = (html: string) => {
    const winUrl = URL.createObjectURL(
        new Blob([html], { type: "text/html" })
    )

    window.open(winUrl, "_self")

    store.commit('progress/setRequest', false)
}