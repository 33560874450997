import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/redirect-to-zaymigo',
        name: 'redirectToZaymigo',
        component: () => import(/* webpackChunkName: "esia-code" */ '@/views/Redirect.vue')
    },
    {
        path: '/view-document',
        name: 'view-document',
        component: () => import(/* webpackChunkName: "main" */ '@/views/Document.vue')
    },
    {
        path: '/esia/after',
        name: 'esia-code',
        component: () => import(/* webpackChunkName: "esia-code" */ '@/views/application-form/esia/Code.vue')
    },
    {
        path: '/',
        name: 'Loan',
        component: () => import('@/layouts/ApplicationForm.vue'),
        children: [{
            path: '/repeater',
            name: 'Repeater',
            meta: {
                step: 1,
                showHeader: false
            },
            component: () => import(/* webpackChunkName: "receive" */ '@/views/repeat-form/Main.vue')
        }, {
            path: '/',
            name: 'Main',
            meta: {
                step: 1
            },
            component: () => import(/* webpackChunkName: "main" */ '@/views/application-form/steps/Main.vue')
        }, {
            path: '/code',
            name: 'code',
            meta: {
                showHeader: false,
                step: 1,
                backButtonTxt: 'Изменить данные'
            },
            component: () => import(/* webpackChunkName: "code" */ '@/views/application-form/steps/Code.vue')
        }, {
            path: '/passport',
            name: 'passport',
            meta: {
                step: 2
            },
            component: () => import(/* webpackChunkName: "passport" */ '@/views/application-form/steps/Passport.vue')
        }, {
            path: '/additional',
            name: 'additional',
            meta: {
                step: 3
            },
            component: () => import(/* webpackChunkName: "additional" */ '@/views/application-form/steps/Additional.vue')
        }, {
            path: '/receive',
            name: 'receive',
            meta: {
                step: 4
            },
            component: () => import(/* webpackChunkName: "receive" */ '@/views/application-form/steps/Recive.vue')
        }, {
            path: '/check',
            name: 'check',
            component: () => import(/* webpackChunkName: "check" */ '@/views/application-form/Check.vue'),
            meta: {
                showMenu: false
            }
        }, {
            path: '/approve',
            name: 'approve',
            component: () => import(/* webpackChunkName: "approve" */ '@/views/application-form/Approve.vue'),
            meta: {
                showMenu: false
            }
        }, {
            path: '/reject',
            name: 'reject',
            component: () => import(/* webpackChunkName: "reject" */ '@/views/application-form/Decline.vue'),
            meta: {
                showMenu: false
            }
        }, {
            path: '/confirmation',
            name: 'confirmation',
            component: () => import(/* webpackChunkName: "confirmation" */ '@/views/application-form/Confirmation.vue'),
            meta: {
                showMenu: false
            }
        }, {
            path: 'update-passport',
            name: 'update-passport',
            component: () => import(/* webpackChunkName: "update-passport" */ '@/views/repeat-form/CheckPassport.vue')
        }, {
            path: '/esia-not-identified',
            name: 'esia-not-identified',
            component: () => import(/* webpackChunkName: "esia-not-identified" */ '@/views/application-form/esia/NotIdentified.vue'),
            meta: {
                controls: false
            }
        }, {
            path: '/esia-exist',
            name: 'esia-exist',
            component: () => import(/* webpackChunkName: "esia-exist" */ '@/views/application-form/esia/Exist.vue'),
            meta: {
                controls: false
            }
        }, {
            path: '*',
            name: 'not-found',
            component: () => import(/* webpackChunkName: "not-found" */ '@/views/application-form/NotFound.vue'),
            meta: {
                controls: false
            }
        }]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
