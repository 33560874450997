import RPC from '@/api/RPC'

export default (type: string, message: string, data: any) => {
    const params = {
        type,
        message,
        data,
    }
    
    RPC({
        method: 'frontendLog',
        params
    }).catch(() => {
        console.warn('logger--params', params)
    })
}