import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import RPC from '@/api/RPC'
// Отключен временно, не удалять
// import SbAntiFraud from "@/helpers/sbAntiFraud";
// import JuicyScore from '@/helpers/juicy'

// new JuicyScore()
// new SbAntiFraud()

declare global {
  interface Window {
      pixel_partner_id: any;
      pixel_partner_uid: any;
  }
}

import { price, phone, timestampDate } from '@/helpers/filters'

Vue.config.productionTip = false

Vue.filter('price', price)
Vue.filter('phone', phone)
Vue.filter('timestampDate', timestampDate)

const middlewareErrorHandler = (error, message, source = 'vue', line = 0, column = 0) => {
  const params = {
    type: 'error',
    message: `${error.toString()} | ${message}`,
    data: {
      stack: error.stack || '',
      source,
      line,
      column
    }
  }

  if (params.data.stack.indexOf('carrotquest') !== -1)
    return

  RPC({
    method: 'frontendLog',
    params
  }).catch((e) => {
    console.warn(e)
  })
}

Vue.config.errorHandler = (error, vm, message) => {
  return middlewareErrorHandler(error, message)
}

window.onerror = (
    message,
    source,
    line,
    column,
    error,
) => {
  return middlewareErrorHandler(error, message, source, line, column)
}

// eslint-disable-next-line
(function (w, d, s, l, i) {
  if (config.GTM_ENABLE == 0)
    return

  const date = new Date()

  w[l] = w[l] || []
  w[l].push({'gtm.start': date.getTime(), event: 'gtm.js'})

  const f = d.getElementsByTagName(s)[0]
  const j = d.createElement(s) as any
  const dl = l != 'dataLayer' ? '&l='+l : ''

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  j!.async = true
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  j!.src = 'https://www.googletagmanager.com/gtm.js?id='+i+dl
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  f!.parentNode!.insertBefore(j, f)
})(window, document, 'script', 'dataLayer', 'GTM-PLNDX6Q')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')