
import application from '@/mixins/application'
import logout from '@/helpers/logout'
import VMask from '@/helpers/VMask'
import getSiteUrl from "@/helpers/getSiteUrl";

export default application.extend({
    data: () => ({
        mobileMenu: false,
        showMenuBar: false,
        mainMenuList: [{
            title: 'Вопросы-ответы',
            link: getSiteUrl('profile', 'faq'),
        }, {
            title: 'Компания',
            link: getSiteUrl('profile', 'about')
        }, {
            title: 'Блог',
            link: getSiteUrl('profile', 'blog')
        }, {
            title: 'Документы',
            link: getSiteUrl('profile', 'docs')
        }, {
            title: 'Контакты',
            link: getSiteUrl('profile', 'contacts')
        }]
    }),
    computed: {
        showLogout(): boolean {
            return this.application.id && this.application.borrower && this.application.borrower.id
        },
        headerLogoLink(): string {
            return getSiteUrl()
        },
        mobileMenuLink(): string {
            return getSiteUrl()
        },
        personalAreaLink(): string {
            return getSiteUrl('profile')
        },
        showMenu(): boolean {
            if (this.$route.meta && this.$route.meta.showMenu != undefined) return this.isRepeater && this.$route.meta.showMenu

            return this.isRepeater
        },
        phone(): any {
            if (this.application.borrower) {
                return this.application.borrower.phone
            } else {
                return null
            }
        },
        headerMenu(): any {
            return [{
                title: 'Занять',
                active: true
            }, {
                title: 'Вопросы-ответы',
                link: getSiteUrl('', 'faq')
            }, {
                title: 'Компания',
                link: getSiteUrl('', 'about')
            }]
        },
        menuList(): any {
            if (!this.phone) return []

            return [{
                title: 'Активный займ',
                path: '/',
                hide: true
            }, {
                title: 'История',
                link: getSiteUrl('profile', 'history')
            }, {
                title: 'Профиль',
                link: getSiteUrl('profile', 'profile')
            }]
        }
    },
    methods: {
        logout() {
            logout()
        },
        toggleMenu() {
            this.mobileMenu = !this.mobileMenu

            if (this.mobileMenu) {
                document.body.setAttribute('style', 'overflow:hidden')
            } else {
                document.body.setAttribute('style', 'overflow:auto')
            }

            setTimeout(() => {
                this.showMenuBar = !this.showMenuBar
            })
        },
        login() {
            this.$store.commit('popup/open', { name: 'SMSAuthPhone' })
        }
    },
    watch: {
        '$route'() {
            if (this.mobileMenu) this.toggleMenu()
        }
    },
    filters: {
        VMask
    }
})
