import api from '@/api/axiosFactory';
import { v4 as uuidv4 } from 'uuid';
// import oldLoanUrl from '@/helpers/oldLoanUrl'
import logout from '@/helpers/logout'
import store from '@/store'
import getSiteUrl from "@/helpers/getSiteUrl";
import Cookies from "js-cookie";
import getStatus from "@/helpers/getStatus";

export default function(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
        const requestData = {
            jsonrpc: '2.0',
            id: uuidv4(),
            ...data
        }
        if(requestData.method !== 'getStatus') {
            if(requestData.params) {
                requestData.params.csrf =  Cookies.get('csrf')
            } else {
                requestData.params = {}
                requestData.params.csrf =  Cookies.get('csrf')
            }
        }
        api({
            url: '/rpc/v1',
            method: 'post',
            data: requestData,
        }).then(async (response: any) => {
            if (response.data.error && response.data.error.message) {
                let show = response.data.error.code !== 403;

                const { code } = response.data.error

                if (code) {
                    switch (code) {
                        // Has open loans with old profile - redirect to old zaymigo
                        // case -32099: {
                        //     window.open(oldLoanUrl(), '_self')
                        //     show = false
                        //     break;
                        // }
                        // Has open loans - redirect to profile
                        case -32098: {
                            window.open(getSiteUrl('profile'), '_self')
                            show = false
                            break;
                        }
                        case -32097: {
                            store.commit('popup/open', { name: 'WrongStep' })
                            show = false
                            break;
                        }
                        case -32004: {
                            getStatus().then((response: any) => {
                                if (response.data.csrf) {
                                    Cookies.set('csrf', response.data.csrf)
                                }
                            })
                        }
                    }
                }

                if (code && code === -32003 || code === -32005) {
                    show = false
                    reject({
                        code: response.data.error.code,
                        message: response.data.error.message,
                        data: response.data.error.data,
                        show
                    });
                    return;
                }

                reject({
                    code: response.data.error.code,
                    message: response.data.error.message,
                    show
                });
            }

            if (response.data.result && response.data.result.validation_errors && response.data.result.validation_errors[''])
                await logout()

            if (response.data.result)
                resolve(response.data.result)
            else
                resolve(response.data)
        }).catch(() => {
            reject({
                code: 0,
                message: 'Отсутствует подключение к интернету',
                show: true
            });
        });
    });
}