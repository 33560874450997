/* eslint-disable */

const statuses = {
    newbie: {
        personal_info: '/',
        passport: '/passport',
        additional_info: '/additional',
        check_code_personal_info: '/code',
        transfer_type: '/receive',
        waiting_result: '/check',
        prior_approval: '/approve',
        check_code_agreement: '/confirmation',
        declined: '/reject'
    },
    repeater: {
        transfer_type: '/repeater',
        passport: '/update-passport',
        waiting_result: '/check',
        prior_approval: '/approve',
        check_code_agreement: '/confirmation',
        declined: '/reject'
    }
}

export default statuses.newbie

export function getUrl(status: string, type = 'newbie') {
    if (!statuses[type])
        return '/'

    return statuses[type][status] || '/'
}
