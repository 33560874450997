import Vue from 'vue'
import captcha from "@/mixins/captcha";

export default captcha.extend({
    computed: {
        application(): any {
            const application = this.$store.state.application || {}

            if (application && application.loan_application)
                return application.loan_application

            return {}
        },
        isRepeater(): boolean {
            return this.application.type === 'repeater'
        }
    }
})
