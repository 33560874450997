<template>
    <footer class="footer">
        <div class="container container--big">
            <p class="footer__logo">
                <img src="~@/assets/logo-gray.png" alt="Zaymigo"/>
            </p>
            <p class="footer__description">
                ООО "Займиго МФК". Регистрационный номер записи в государственном реестре микрофинансовых организаций 651303322004222. ОГРН 1135260005363, присвоен 07.11.2013г. Адрес: 603093, г. Нижний Новгород, ул. Ковровская 21А, помещение 603. Оставляя свои контактные данные на сайте zaymigo.ru, пользователь дает свое согласие на получения информации от компании ООО "Займиго МФК" и партнеров компании.
            </p>
        </div>
    </footer>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'FooterZaym'
    })
</script>

<style lang="scss">
    @import "../style/footer";
</style>
