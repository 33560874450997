import application from './application'
import RPC from '@/api/RPC'

export default {
    state: {
        additional_services: [],
        formula: null,
        amount: null,
        formulaNoDiscount: null,
    },
    mutations: {
        setAdditionalServices(state, services: any[]) {
            const agreements = (application.state.loan_application || {}).agreements || []

            const getAgreementByName = (name: string): any => {
                let soughtAgreement = {} as any
                let i = agreements.length

                while (!soughtAgreement.name && i) {
                    i--

                    const agreement = agreements[i]

                    if (agreement.name !== name)
                        continue;

                    soughtAgreement = agreement
                }

                return soughtAgreement
            }

            services.forEach((service: any) => {
                service.agreement = (getAgreementByName(service.code)).val || false
            })

            state.additional_services = services
        },
        setFormula(state, formula) {
            state.formula = formula
        },
        setNoDiscountFormula(state, formula) {
            state.formulaNoDiscount = formula
        },
        clear(state) {
            state.additional_services = []
        },
        setNewAmount(state, amount) {
            state.amount = amount
        }
    },
    actions: {
        getFormula({ state, commit }) {
            if (state.formula)
                return

            const rates = application.state.rates

            const params = {
                term: rates.term.min,
                amount: rates.amount.min
            }

            RPC({
                method: 'getReturnAmount',
                params
            }).then((response) => {
                commit('setFormula', response.formula)
            })
        }
    },
    namespaced: true
}
