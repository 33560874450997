
  import Vue from 'vue'

  import HeaderZaym from '@/components/uniq/header'
  import FooterZaym from '@/components/uniq/footer'

  import Popup from '@/components/common/popup'
  import VContent from '@/components/common/content'
  import ErrorWin from '@/components/popup/Error.vue'

  export default Vue.extend({
    name: 'App',
    computed: {
      popup(): string {
        return this.$store.state.popup.open
      },
      fullPopup(): string {
        return this.$store.state.popup.full
      },
    },
    watch: {
      '$route.name'() {
        window.scrollTo(0, 0)
      }
    },
    methods: {
      bodyClickHandler () {
        this.$store.commit('popup/setFocusedField', 'closeDropdown')
      },  
    },
    created() {
      document.addEventListener('click', this.bodyClickHandler)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.bodyClickHandler)
    },
    components: {
      HeaderZaym,
      FooterZaym,
      Popup,
      VContent,
      MobileApp: () => import(/* webpackPrefetch: true */ '@/components/common/mobile-app/component/MobileApp.vue'),
      Auth: () => import(/* webpackPrefetch: true */ '@/components/popup/Auth.vue'),
      ForgotPassword: () => import(/* webpackPrefetch: true */ '@/components/popup/ForgotPassword.vue'),
      ForgotPasswordCode: () => import(/* webpackPrefetch: true */ '@/components/popup/ForgotPasswordCode.vue'),
      ForgotPasswordNewPassword: () => import(/* webpackPrefetch: true */ '@/components/popup/ForgotPasswordNewPassword.vue'),
      Esia: () => import('@/components/popup/application-form/Esia.vue'),
      Approval: () => import('@/components/popup/approval/component/Approval.vue'),
      Calculator: () => import(/* webpackPrefetch: true */ '@/components/application-form/calculator'),
      ErrorWin,
      RecoveryAccountWin: () => import('@/components/popup/passport-exists/RecoveryAccount.vue'),
      Agreement: () => import(/* webpackPrefetch: true */ '@/components/popup/application-form/Agreement.vue'),
      EsiaInfo: () => import('@/components/popup/application-form/EsiaInfo.vue'),
      PromoCode: () => import('@/components/popup/Promocode.vue'),
      AdditionalService: () => import('@/components/popup/additional-service'),
      WrongStep: () => import('@/components/popup/WrongStep.vue'),
      SMSAuthPhoneExisted: () => import('@/components/popup/sms-auth/PhoneExisted.vue'),
      SMSAuthCodeExisted: () => import('@/components/popup/sms-auth/CodeExisted.vue'),
      SMSAuthPhone: () => import('@/components/popup/sms-auth/Phone.vue'),
      SMSAuthCode: () => import('@/components/popup/sms-auth/Code.vue'),
      ChangeTerm: () => import('@/components/popup/ChangeTerm.vue'),
      WarningChangeTerm: () => import('@/components/popup/WarningChangeTerm.vue'),
    }
  })
