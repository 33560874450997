import RPC from '@/api/RPC'

import application from '@/store/modules/application'
import Cookies from 'js-cookie'
import getSiteUrl from "@/helpers/getSiteUrl";

const logout = () => new Promise<void>((resolve, reject) => {
    RPC({
        method: 'logout',
        params: {
            // eslint-disable-next-line
            loan_application_id: application.state.id
        }
    }).then(() => {
        try {
            localStorage.clear()
        } catch (e) {
            console.warn(e)
        }

        Object.keys(Cookies.get()).forEach((cookieName) => {
            Cookies.remove(cookieName)
        })
        window.open(getSiteUrl(), '_self')
        resolve()
    }).catch((e) => {
        reject(e)
    })
})

export default logout
