import Vue from 'vue';
import Vuex from 'vuex';

import progress from './modules/progress'
import popup from './modules/popup'
import application from './modules/application'
import esia from './modules/esia'
import calculator from './modules/calculator'
import map from './modules/map'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        progress,
        popup,
        application,
        esia,
        calculator,
        map
    },
    strict: true
});
