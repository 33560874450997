
    import Vue from 'vue'
    import popup from '@/mixins/popup'

    export default Vue.extend({
        name: 'Error',
        mixins: [popup],
        methods: {
            reloadPage() {
                location.reload()
            }
        }
    })
