
    import Vue from 'vue'

    export default Vue.extend({
        name: 'Popup',
        data: () => ({
            isPopup: true
        }),
        methods: {
            close() {
                this.$store.commit('popup/close')
            }
        },
        computed: {
            width: {
                get(): number {
                    return this.$store.state.popup.width
                }
            },
            back: {
                get(): string {
                    return this.$store.state.popup.back
                }
            },
            loaded: {
                get(): boolean {
                    return this.$store.state.popup.loaded
                }
            }
        }
    })
